<i18n>
{
  "en": {
    "title": "Products",
    "search": "Search for name",
    "create": "New product",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "import": "Import",
    "not-found": "Products not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Product",
    "column-2": "Created at",
    "column-3": "Updated at",
    "nested-menu-1": "Duplicate"
  },
  "pt-BR": {
    "title": "Produtos",
    "search": "Pesquise pelo nome",
    "create": "Novo produto",
    "create-mobile": "Novo",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "import": "Importar",
    "not-found": "Nenhum produto encontrado",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Produto",
    "column-2": "Data de Cadastro",
    "column-3": "Última atualização",
    "nested-menu-1": "Duplicar"
  }
}
</i18n>

<template>
  <div id="products-configs">
    <nav-top
      :returnPage="!isDesktop ? 'records' : 'configurations'"
      :title="$t('title')"
    >
      <template #top-content>
        <we-input
          type="search"
          v-model="search"
          @input="searchProducts"
          :inputLabel="$t('search')"
          cy="search-in-products"
        />
        <we-button
          class="green mobile-icon"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="plus"
          @click="$router.push({ name: 'createProduct' })"
          cy="create-product-cta"
        />
      </template>
    </nav-top>
    <board-list class="products">
      <router-view />
      <template #filters>
        <we-pagination :pages="productsPages" @change="selectPage($event)" />
        <we-button
          class="mobile-icon"
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterProduct' })"
          cy="filter-in-products"
        />
        <we-balloon v-if="filterActive">
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export-results')"
              color="icon-primary"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFilteredFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text">
                {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          v-if="filterActive"
          :icon="['fas', 'edit']"
          :name="$t('edit-filtered')"
          @click="massUpdateOpened = true"
          cy="update-filtered-products"
        />
        <mass-update
          :open="massUpdateOpened"
          @close="massUpdateOpened = false"
          @confirm="doRequests({ reset: false })"
          :total-records="productsPages.total"
        />
        <we-icon-button
          v-if="filterActive"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-filtered')"
          color="icon-red"
          @click="confirmDeleteOpened = true"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          :total-records="productsPages.total"
          @confirm="deleteFilteredData()"
          @close="confirmDeleteOpened = false"
        />
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          @click="doRequests({ reset: true, page: 1 })"
          :name="$t('reload')"
          cy="reload-products"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export')"
              cy="export-products-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-products">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingProducts" />
        <div class="labels">
          <div
            class="label"
            :class="
              elem.orderBy === productsSort.orderBy
                ? productsSort.direction
                : ''
            "
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="products.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                class="success"
                :text="$t('create')"
                icon="plus"
                @click="$router.push({ name: 'createProduct' })"
              />
            </template>
          </we-not-found>
          <v-card
            class="card-list we-card"
            v-for="(product, i) in products"
            :key="i"
            @click="openProduct(product.id)"
            @contextmenu="showOptions({ event: $event, product })"
          >
            <div class="column profile">
              <we-rounded-picture
                :picture="product.picture"
                :name="product.name"
                :text="$options.filters.firstLetters(product.name)"
                type="product"
                size="36"
              />
              <div class="data">
                <we-text-tooltip class="name" :text="product.name" />
                <we-text-tooltip
                  class="value"
                  :text="$options.filters.valueToReais(product.price)"
                />
              </div>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(product.created_at)"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(product.updated_at)"
                lines="1"
              />
            </div>
          </v-card>
          <we-nested-menu
            name="New Menu"
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            :absolute="true"
            :offset-x="true"
            :menu-items="productActionsMenu"
            @we-nested-menu-click="onMenuItemClick"
          />
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      loadingProducts: true,
      loadingBtn: false,
      initialPage: 1,
      search: "",
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "name",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "created_at",
          direction: "desc"
        },
        {
          name: this.$t("column-3"),
          orderBy: "updated_at",
          direction: "desc"
        }
      ],
      showMenu: false,
      selectedProduct: {},
      x: 0,
      y: 0
    };
  },
  computed: {
    productActionsMenu() {
      return [
        {
          name: this.$t("nested-menu-1"),
          icon: ["far", "copy"],
          action: () => {
            this.duplicateProduct();
          }
        }
      ];
    },
    products() {
      return this.$store.getters.getProducts;
    },
    productsPages() {
      return this.$store.getters.getProductsPages;
    },
    productsSort() {
      return this.$store.getters.getProductsSort;
    },
    productsCanLoad() {
      return this.productsPages?.last_page != this.productsPages?.current_page;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "productsRequest",
      "createProductRequest",
      "searchProductsRequest",
      "filterProductsRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "createProductRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingProducts = true;
      await this.deleteFilteredRecords();
      await this.doRequests({ reset: true, page: 1 });
    },
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    async duplicateProduct() {
      delete this.selectedProduct.id;

      if (this.selectedProduct?.products?.length > 0) {
        this.selectedProduct.products = this.selectedProduct.products.map(e => {
          return {
            id: e.pivot.product_id,
            amount: e.amount
          };
        });
      }

      await this.createProductRequest(this.selectedProduct);

      this.doRequests({ page: this.productsPages?.current_page });
    },
    showOptions({ event, product }) {
      this.selectedProduct = { ...product };

      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    exportFile() {
      const payload = {
        entity: "products"
      };

      this.exportRequest(payload);
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("products-filtered");
      }
    },
    openImport() {
      this.$router.push({ name: "importProducts" });
    },
    searchProducts() {
      this.loadingProducts = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.productsSort.orderBy) {
        orderBy = {
          orderBy: this.productsSort.orderBy,
          direction: this.productsSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.search?.length > 0) {
          await this.searchProductsRequest({
            value: this.search,
            page: 1,
            ...orderBy
          });
        } else {
          await this.doRequests({ page: 1 });
        }
        this.loadingProducts = false;
      }, 600);
    },
    openProduct(id) {
      this.$router.push({ name: "editProduct", params: { id: id } });
    },
    async orderBy(elem) {
      this.loadingProducts = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setProductsSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterProductsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.productsPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchProductsRequest({
          value: this.search,
          page: this.productsPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.productsRequest({
          page: this.productsPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingProducts = false;
    },
    async doRequests({ reset, page }) {
      this.loadingProducts = true;

      if (reset) {
        this.productsPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setProductsSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      let orderBy = {};
      if (this.productsSort.orderBy) {
        orderBy = {
          orderBy: this.productsSort.orderBy,
          direction: this.productsSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterProductsRequest({
          filters: this.exportParams?.payload?.filters,
          page: page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchProductsRequest({
          value: this.search,
          page: page,
          ...orderBy,
          paginate: true
        });
      } else {
        await this.productsRequest({
          page: page,
          ...orderBy
        });
      }

      this.loadingProducts = false;
    },
    async selectPage(val) {
      this.doRequests({ page: val });
    }
  },
  async mounted() {
    await this.doRequests({ reset: true, page: 1 });
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash.length > 0) {
      return;
    }
    if (from.name === "filterProduct") {
      this.search = "";
    }
    if (from.name === "importProducts") {
      this.doRequests({ page: 1 });
    }
    if (to.query.reload) {
      this.doRequests({ page: this.productsPages?.current_page });
    }
    next();
  }
};
</script>

<style lang="scss">
#products-configs {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
