<i18n>
{
  "en": {
    "title": "New Product",
    "input-1": "Name",
    "input-2": "Color",
    "input-3": "Price",
    "input-4": "SKU",
    "create": "Create product"
  },
  "pt-BR": {
    "title": "Novo Produto",
    "input-1": "Nome",
    "input-2": "Cor",
    "input-3": "Preço",
    "input-4": "SKU",
    "create": "Criar produto"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateProduct" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateProduct" />
          </div>
        </div>
        <div class="modal-body">
          <div class="fields">
            <we-input
              type="text"
              :label="$t('input-1')"
              v-model="product.name"
              cy="create-product-name"
            />
            <we-input
              type="color"
              :label="$t('input-2')"
              v-model="product.color"
              :items="colorList"
              cy="create-product-color"
            />
            <we-input
              v-model="product.price"
              type="money"
              :label="$t('input-3')"
            />
            <we-input
              v-model="product.sku"
              type="text"
              :label="$t('input-4')"
            />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('create')"
            icon="plus"
            :loading="btnLoading"
            @click="createProduct"
            cy="create-product"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      product: {
        name: "",
        price: 0,
        color: "#0C80F2"
      }
    };
  },
  computed: {
    colorList() {
      return this.$store.getters.getProductColors;
    }
  },
  methods: {
    ...mapActions(["createProductRequest"]),
    closeCreateProduct() {
      this.$router.push({
        name: "productsConfigs"
      });
    },
    async createProduct() {
      this.btnLoading = true;
      const response = await this.createProductRequest(this.product);

      if (response?.data?.id) {
        this.$router.push({
          name: "editProduct",
          params: { id: response.data.id },
          query: { reload: true }
        });
      }
      this.btnLoading = false;
    }
  }
};
</script>
