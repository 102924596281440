<i18n>
{
  "en": {
    "delete-title": "Delete product",
    "main-data-title": "Main Data",
    "main-data-input-1": "Name",
    "main-data-input-2": "Color",
    "main-data-input-3": "Price",
    "main-data-input-4": "SKU",
    "bundle-title": "Bundle",
    "bundle-input-1": "Bundle products",
    "image-title": "Image",
    "image-input-1-info": "Upload product image preferably with transparent background",
    "custom-fields-title": "Custom Fields",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "update": "Save updates",
    "update-mobile": "Save",
    "remove": "Remove"
  },
  "pt-BR": {
    "delete-title": "Deletar produto",
    "main-data-title": "Dados Principais",
    "main-data-input-1": "Nome",
    "main-data-input-2": "Cor",
    "main-data-input-3": "Preço",
    "main-data-input-4": "SKU",
    "bundle-title": "Pacote",
    "bundle-input-1": "Produtos do pacote",
    "image-title": "Imagem",
    "image-input-1-info": "Faça upload da imagem do produto de preferência com fundo transparente",
    "custom-fields-title": "Campos Customizados",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "update": "Salvar alterações",
    "update-mobile": "Salvar",
    "remove": "Remover"
  }
}
</i18n>

<template>
  <modal-entity
    :drag-enabled="false"
    class="product"
    @close="closeProduct"
    :menuItems="menuItems"
    @currentMenuItem="currentMenuItem = $event"
    :loading="loading"
  >
    <template #actions>
      <confirm-modal
        :open="confirmDeleteOpened"
        @close="confirmDeleteOpened = false"
        @confirm="deleteProduct(product.id)"
      />
      <we-icon-button
        :icon="['far', 'trash-alt']"
        :name="$t('delete-title')"
        direction="bottom"
        @click="confirmDeleteOpened = true"
        class="big-mobile"
        cy="delete-product"
      />
      <we-icon-button icon="times" class="big-mobile" @click="closeProduct" />
    </template>
    <template #data>
      <div class="data-grid">
        <we-rounded-picture
          type="product"
          :picture="product.picture"
          :name="product.name"
          :text="$options.filters.firstLetters(product.name)"
          size="79"
        />
        <div class="data-info">
          <we-text-tooltip class="name" :text="`${product.name}`" lines="1" />
          <div class="data-list">
            <span class="price success--text">
              {{ product.price | valueToReais }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form">
        <section
          id="profile"
          name="profile"
          :class="{ 'hide-mobile': currentMenuItem != 'profile' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="cube" />
            </div>
            <div class="section-name">
              {{ $t("main-data-title") }}
            </div>
          </div>
          <div class="fields">
            <we-input
              type="text"
              :label="$t('main-data-input-1')"
              v-model="product.name"
            />
            <we-input
              type="color"
              :label="$t('main-data-input-2')"
              v-model="product.color"
              :items="colorList"
            />
            <we-input
              v-model="product.price"
              type="money"
              :label="$t('main-data-input-3')"
            />
            <we-input
              v-model="product.sku"
              type="text"
              :label="$t('main-data-input-4')"
            />
          </div>
        </section>
        <section
          id="bundle"
          name="bundle"
          :class="{ 'hide-mobile': currentMenuItem != 'bundle' }"
          v-if="!loading"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="cubes" />
            </div>
            <div class="section-name">
              {{ $t("bundle-title") }}
              <span class="success--text">
                {{ bundlePrice | valueToReais }}
              </span>
            </div>
          </div>
          <div class="fields fill">
            <we-input-products
              :key="updateProductInput"
              :label="$t('bundle-input-1')"
              multiple
              v-model="selectedProducts"
            />
            <div class="product-list">
              <div
                class="product-item"
                v-for="(product, i) in selectedProducts"
                :key="product.id"
              >
                <we-rounded-picture
                  :picture="product.picture"
                  :name="product.name"
                  :text="product.name"
                  type="product"
                  size="50"
                />
                <div class="product-content">
                  <we-text-tooltip class="product-name" :text="product.name" />
                  <div class="product-actions">
                    <div class="price-quantity">
                      <div class="product-price">
                        {{ product.price | valueToReais }}
                      </div>
                      <div class="quantity-selector">
                        <div class="quantity-button" @click="sub(i)">
                          <font-awesome-icon icon="minus" />
                        </div>
                        <input
                          type="text"
                          v-maska="'##'"
                          v-model="product.amount"
                          @blur="checkAmount($event, i)"
                        />
                        <div class="quantity-button" @click="add(i)">
                          <font-awesome-icon icon="plus" />
                        </div>
                      </div>
                    </div>
                    <we-icon-button
                      @click="remove(i)"
                      class="small"
                      :icon="['far', 'trash-alt']"
                      :name="$t('remove')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="picture"
          name="picture"
          :class="{ 'hide-mobile': currentMenuItem != 'picture' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="image" />
            </div>
            <div class="section-name">
              {{ $t("image-title") }}
            </div>
          </div>
          <div class="fields">
            <we-file-upload
              :info="$t('image-input-1-info')"
              @change="onFileChange"
              height="140px"
              padding="0"
              :file="product.picture"
              :loading="imgLoading"
              class="double-column"
            />
          </div>
        </section>
        <section
          id="custom-fields"
          name="custom-fields"
          :class="{ 'hide-mobile': currentMenuItem != 'custom-fields' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="folder-plus" />
            </div>
            <div class="section-name">
              {{ $t("custom-fields-title") }}
            </div>
          </div>
          <div class="fields">
            <we-input
              v-for="(field, i) in customFieldsInputs"
              :class="field.layout"
              :key="i"
              :placeholder="field.placeholder"
              :type="field.type"
              :label="field.label"
              :items="field.values"
              :isRequired="field.is_required"
              :readonly="field.is_read_only"
              v-model="productCustomFields[field.name]"
              :mask="field.mask"
            />
          </div>
        </section>
      </v-form>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ product.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ product.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateProduct(product)"
        class="green mobile-icon"
        :text="$t('update')"
        :mobile-text="$t('update-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      updateProductInput: 0,
      confirmDeleteOpened: false,
      loading: true,
      btnLoading: false,
      imgLoading: false,
      picture: {},
      searchLoading: false,
      firstEvent: true,
      debounce: null,
      selectedProducts: [],
      reloadProducts: false,
      currentMenuItem: "profile"
    };
  },
  computed: {
    menuItems() {
      return [
        {
          id: "profile",
          name: this.$t("main-data-title"),
          icon: "cube"
        },
        {
          id: "bundle",
          name: this.$t("bundle-title"),
          icon: "cubes"
        },
        {
          id: "picture",
          name: this.$t("image-title"),
          icon: "image"
        },
        {
          id: "custom-fields",
          name: this.$t("custom-fields-title"),
          icon: "folder-plus"
        }
      ];
    },
    colorList() {
      return this.$store.getters.getProductColors;
    },
    product() {
      return this.$store.getters.getProduct;
    },
    customFieldsInputs() {
      return this.$store.getters.getCustomFields;
    },
    productCustomFields() {
      return this.$store.getters.getProductCustomFields;
    },
    bundlePrice() {
      return (
        this.selectedProducts.reduce(
          (sum, product) => sum + product.price * product.amount,
          0
        ) || 0
      );
    }
  },
  methods: {
    ...mapActions([
      "productRequest",
      "updateProductRequest",
      "deleteProductRequest",
      "updateProductPictureRequest",
      "deleteProductPictureRequest",
      "customFieldsRequest"
    ]),
    updateSelectedProducts(val) {
      this.selectedProducts = val;
    },
    remove(i) {
      this.selectedProducts.splice(i, 1);
      this.updateProductInput += 1;
    },
    add(i) {
      const updatedProduct = this.selectedProducts[i];
      updatedProduct.amount++;
      this.$set(this.selectedProducts, i, updatedProduct);
    },
    sub(i) {
      const updatedProduct = this.selectedProducts[i];
      if (updatedProduct.amount > 1) {
        updatedProduct.amount--;
      }
      this.$set(this.selectedProducts, i, updatedProduct);
    },
    checkAmount(e, i) {
      const updatedProduct = this.selectedProducts[i];
      if (e.target.value <= 0) {
        updatedProduct.amount = 1;
      }
      this.$set(this.selectedProducts, i, updatedProduct);
    },
    selectImage() {
      this.$refs.file.click();
    },
    async onFileChange(file) {
      this.picture = file;
      this.imgLoading = true;
      this.reloadProducts = true;
      await this.updateProductPictureRequest(this.picture);
      this.imgLoading = false;
    },
    async updateProduct(product) {
      this.btnLoading = true;
      product.products = this.selectedProducts.map(e => {
        return {
          id: e.id,
          amount: Number(e.amount)
        };
      });
      product.custom_fields = [this.productCustomFields];
      this.reloadProducts = true;
      await this.updateProductRequest(product);
      this.btnLoading = false;
    },
    async deleteProduct(id) {
      this.loading = true;
      this.reloadProducts = true;
      await this.deleteProductRequest(id);
      this.loading = false;
      this.closeProduct();
    },
    closeProduct() {
      this.$router.push({
        name: "productsConfigs",
        query: { reload: this.reloadProducts }
      });
    },
    async doRequests() {
      await this.productRequest(this.$route.params.id);
      document.title = this.product.name;
      await this.customFieldsRequest({
        entity: "product"
      });
      if (this.product.products?.length > 0) {
        this.selectedProducts = this.product.products.map(e => {
          return {
            id: e.pivot.related_product_id,
            name: e.name,
            price: e.price,
            amount: e.amount,
            picture: e.picture
          };
        });
      }
      this.loading = false;
      setTimeout(() => {
        this.$refs.form.validate();
      }, 100);
    }
  },
  async mounted() {
    await this.doRequests();
  }
};
</script>

<style lang="scss"></style>
